<template>
  <div id="app">
    <div v-if="userInfo">
      <div class="header-wrap fdr aic">
        <div>智能雷达系统 | 后台管理</div>
        <el-button type="text" style="color:#fff" @click="loginOut">退出登录</el-button>
      </div>
      <div>
        <div class="doctor-wrap">
          <el-card class="box-card">
            <div class="title-wrap fdr aic jcsb">
              <div>版本管理</div>
              <el-button type="primary" size="small"
                @click="dialogVisible = true; dialogType = 3; dialogTitle = '添加版本'">添加版本</el-button>
            </div>
            <div class="table-wrap">
              <el-table :data="versionList" stripe border style="width: 100%">
                <el-table-column prop="versionName" label="版本号">
                </el-table-column>
                <el-table-column prop="description" label="版本描述">
                </el-table-column>
                <el-table-column prop="createTime" label="上传时间">
                </el-table-column>
                <el-table-column prop="fileUrl" label="版本文件">
                  <template slot-scope="{row}">
                    <el-button type="text"
                      @click="downloadFile(row.fileUrl.split('/').pop().replace('/', ''))">{{ row.fileUrl.split('/').pop().replace('/',
                      '')}}</el-button>
                    <!-- <a :href="'//' + row.fileUrl.split('/').pop()" target="_blank">{{row.fileUrl.split('/').pop().replace('/', '')}}</a> -->
                  </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                  <template slot-scope="scope">
                    <!-- <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"  @click="editItem(scope.row, 1)">编辑</el-button> -->
                    <el-popconfirm title="确定删除吗？" @onConfirm="deleteVersion(scope.row.id)">
                      <el-button type="text" size="small" slot="reference">删除</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
        <div class="doctor-wrap">
          <el-card class="box-card">
            <div class="title-wrap fdr aic jcsb">
              <div class="flex-row">
                <div>设备管理</div>
                <el-input v-model="searchDevice" size="small" placeholder="请输入设备序列号"
                  style="width: 200px; margin:0 12px;" />
                <div>
                  <el-button type="primary" size="small" @click="queryDevice">查 询</el-button>
                </div>
              </div>
              <el-button type="primary" size="small"
                @click="dialogVisible = true; dialogType = 1; dialogTitle = '添加设备'">添加设备</el-button>
            </div>
            <div class="table-wrap">
              <el-table :data="deviceList" stripe border style="width: 100%">
                <el-table-column prop="name" label="设备名称">
                </el-table-column>
                <el-table-column prop="version" label="设备版本">
                </el-table-column>
                <el-table-column prop="port" label="设备端口">
                </el-table-column>
                <!-- <el-table-column
                    prop="mac"
                    label="设备MAC地址"
                    min-width="150"
                    ></el-table-column> -->
                <el-table-column prop="mac" label="设备序列号" min-width="150"></el-table-column>
                <el-table-column prop="status" label="设备状态">
                  <template slot-scope="{row}">
                    <span v-if="row.status" class="dot" :class="{ 'outline': row.status == 2 }"></span>
                    <span>{{ { 1: '在 线', 2: '离 线' }[row.status] || '--' }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="radarHeight" label="设备高度"></el-table-column>
                <el-table-column prop="downAngle" label="设备角度"></el-table-column>
                <!-- <el-table-column
                    prop="bandWidth"
                    label="设备带宽"
                    ></el-table-column> -->
                <el-table-column prop="isUpdate" label="更新状态">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                  <template slot-scope="scope">
                    <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"
                      @click="editItem(scope.row, 1)">编辑</el-button>
                    <el-popconfirm title="确定删除吗？" @onConfirm="deleteDevice(scope.row.id)">
                      <el-button type="text" size="small" slot="reference">删除</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
        <div class="patient-wrap">
          <el-card class="box-card">
            <div class="title-wrap fdr aic jcsb">
              <div class="flex-row">
                <div>用户管理</div>
                <el-input v-model="searchPhone" size="small" placeholder="请输入用户手机号"
                  style="width: 200px; margin:0 12px;" />
                <div>
                  <el-button type="primary" size="small" @click="queryUser">查 询</el-button>
                </div>
              </div>
              <div>
                <el-button type="primary" size="small"
                  @click="dialogVisible = true; dialogType = 2; dialogTitle = '添加用户'">添加用户</el-button>
              </div>
            </div>
            <div class="table-wrap">
              <el-table :data="filteredUserList" stripe border style="width: 100%">
                <el-table-column prop="name" label="姓名" min-width="150">
                </el-table-column>
                <el-table-column prop="parentId" label="是否主账号" align='center' min-width="120">
                  <template slot-scope="scope">
                    <el-tag v-if="!scope.row.parentId" type="primary" size="mini">主账号</el-tag>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="登陆号码" min-width="150">
                </el-table-column>
                <!-- 修改 -->
                <el-table-column prop="privileges" label="用户权限" min-width="120" align="center">
                  <template slot-scope="scope">
                    <span>{{ userPrivileges[scope.row.phone] || '加载中...' }}</span>
                  </template>
                </el-table-column>

                <!-- <el-table-column
                    prop="password"
                    label="登录密码"
                    min-width="120"
                  >
                  </el-table-column> -->

                <el-table-column fixed="right" label="操作" align="center" min-width="150">
                  <template slot-scope="scope">
                    <div class="fdr aic jcc">
                      <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"
                        @click="editItem(scope.row, 2)">编辑</el-button>
                      <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"
                        @click="openBand(scope.row)">关联设备</el-button>
                        <!-- 新增删除按钮 -->
                        <el-popconfirm title="确定删除该用户吗？" @onConfirm="deleteUser(scope.row.phone)">
                          <el-button style="margin-right: 10px;" type="text" size="small" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>

        <div class="patient-wrap">
          <el-card class="box-card">
            <div class="title-wrap fdr aic jcsb">
              <div class="flex-row">
                <div>用户场景管理</div>
                <el-input v-model="searchRoom" size="small" placeholder="请输入房间名称"
                  style="width: 200px; margin:0 12px;" />
                <div>
                  <el-button type="primary" size="small" @click="getRoomList">查 询</el-button>
                </div>
              </div>
            </div>
            <div class="table-wrap">
              <el-table :data="roomList" stripe border style="width: 100%">
                <el-table-column prop="name" label="房间名称" min-width="150">
                </el-table-column>
                <el-table-column prop="room_x_length" label="房间长" min-width="150">
                </el-table-column>
                <el-table-column prop="room_y_length" label="房间宽" min-width="150">
                </el-table-column>
                <el-table-column fixed="right" label="操作" align="center" min-width="150">
                  <template slot-scope="scope">
                    <div class="fdr aic jcc">
                      <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"
                        @click="openShare(scope.row.id)">分享场景</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
        <!-- <div class="doctor-wrap">
            <el-card class="box-card">
                <div class="title-wrap fdr aic jcsb">
                    <div>告警信息</div>
                </div>
                <div class="table-wrap">
                    <el-table
                    :data="warningList"
                    stripe
                    border
                    style="width: 100%">
                    <el-table-column
                      prop="name"
                      label="设备名称"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="port"
                      label="端口"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="port"
                      label="情况描述"
                    >
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    label="操作"
                    width="150"
                    >
                      <template slot-scope="scope">
                        <el-button style="margin-right: 10px;" type="text" size="small" slot="reference"  @click="editItem(scope.row, 1)">编辑</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
            </el-card>
        </div> -->
      </div>
    </div>
    <div v-else class="loginPage">
      <div class="loginPage-title">智能感知雷达管理端</div>
      <div class="loginForm">
        <div class="loginForm-title">用户登录</div>
        <div class='loginForm-form'>
          <el-form label-position="right" label-width="0px" :model="loginForm">
            <el-form-item label="" size="small">
              <el-input v-model="loginForm.username" class="loginForm-input" placeholder="请输入账号"></el-input>
              <el-input v-model="loginForm.password" type="password" class="loginForm-input" placeholder="请输入密码"
                style="margin:16px 0" @keyup.enter="handleLogin"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button class="loginForm-button" type="primary" @click="handleLogin"
          @keyup.enter="handleLogin">登录</el-button>
      </div>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false"
      :before-close="handleClose">
      <div>
        <el-form label-position="right" label-width="100px" :model="form" v-if="dialogType == 1">
          <el-form-item label="设备名称" size="small">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <!-- <el-form-item label="设备端口" size="small">
                  <el-input v-model="form.port"></el-input>
                </el-form-item> -->
          <!-- <el-form-item label="设备MAC地址" size="small">
                  <el-input v-model="form.mac"></el-input>
                </el-form-item> -->
          <el-form-item label="设备序列号" size="small">
            <el-input v-model="form.mac"></el-input>
          </el-form-item>
          <el-form-item label="设备高度" size="small">
            <el-input v-model="form.radarHeight"></el-input>
          </el-form-item>
          <el-form-item label="设备下方角度" size="small">
            <el-input v-model="form.downAngle"></el-input>
          </el-form-item>

          <!-- <el-form-item label="设备带宽" size="small">
                  <el-input v-model="form.bandWidth"></el-input>
                </el-form-item> -->
        </el-form>
        <el-form label-position="left" label-width="80px" :model="form" v-else-if="dialogType == 2">
          <el-form-item label="姓名" size="small">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="登录号码" size="small">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <!-- <el-form-item label="登录密码" size="small">
                  <el-input v-model="form.password"></el-input>
                </el-form-item> -->
          <!-- <el-form-item label="关联设备" size="small">
                    <el-select v-model="form.association_device" placeholder="请选择"  multiple collapse-tags>
                        <el-option
                          v-for="item in deviceList"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                </el-form-item> -->
          <el-form-item label="分享账号" size="small">
            <div v-for="(item, index) in accountList" :key="index" style='display:flex;margin-bottom:8px'>
              <el-input v-model="item.value" style="width:60%"></el-input>
              <div style="width:100px;margin-left:20px">
                <el-button type="danger" size='mini' icon="el-icon-minus" @click='delAccount(index)'></el-button>
                <el-button type="primary" size='mini' icon="el-icon-plus" @click="addAccount"
                  v-if="index == accountList.length - 1"></el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="权限设置" size="small">
            <!-- 
                    该项中包括两个按钮，一个是“普通用户”，一个是“管理员”
                    当用户点击“普通用户”按钮（该按钮的id设为1）
                    当用户点击“管理员”按钮（该按钮的id设为0）
                    当用户点击对话框的确认按钮时，调用addPrivilege方法，并且把用户选择的按钮id以及用户输入的form.phone传给addPrivilege方法
                  -->
            <el-radio-group v-model="privilegeId">
              <el-radio :label="1">普通用户</el-radio>
              <el-radio :label="0">管理员</el-radio>
              <el-radio :label="2">申请中</el-radio>
              <el-radio :label="3">拒绝申请</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <el-form label-position="left" label-width="80px" :model="form" v-else-if="dialogType == 3">
          <el-form-item label="版本号" size="small">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="版本描述" size="small">
            <el-input v-model="form.description" type="textarea" :rows="3"></el-input>
          </el-form-item>
          <el-form-item label="版本文件" size="small">
            <el-upload class="upload-demo" :limit="1" :file-size="20 * 1024 * 1024" action="" ref="upload"
              :on-change="changFile" :auto-upload="false">
              <el-button size="small" type="primary">选择文件</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false; initData()" size="small">取 消</el-button>
        <el-button type="primary" @click="modifyUser(dialogType)" size="small" v-loading="loading">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="绑定设备" :visible.sync="bandDialog" width="650px" :close-on-click-modal="false"
      :before-close="closeBand">
      <div>
        <!-- <el-select v-model="deviceIds" multiple placeholder="请选择" style="width:80%">
                      <el-option v-for="item in deviceList" :key='item.index' :value="item.id" :label='item.name'></el-option>
                  </el-select> -->
        <el-transfer v-model="deviceIds" :data="bandDeviceList" :titles="['所有设备', '已绑定设备']"></el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeBand" size="small">取 消</el-button>
        <el-button type="primary" @click="bandUser" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="场景分享" :visible.sync="shareDialog" width="650px" :close-on-click-modal="false"
      :before-close="closeShare">
      <div>
        <div>房间内的设备: <span style='color:#333;'>{{ roomDeviceName }}</span></div>
        <div style="margin:16px 0;">将该房间与设备共享给
          <el-select v-model="shareRoomUser" filterable>
            <el-option v-for="item in userList" :key='item.id' :value="item.id" :label='item.name'></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeShare" size="small">取 消</el-button>
        <el-button type="primary" @click="shareRoom" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteUser, getDevice, getUser, addDevice, editDevice, delDevice, addUser, editUser, delUser, getVersion, addVersion, delVersion, bandUserDevice, queryUserBandList, queryUserDevice, login, getRoomList, shareRoom } from './js/api.js'
import { deOptions } from './js/data.js'
export default {
  data() {
    return {
      userPrivileges: {}, // 用于存储每个用户的权限信息
      privilegeId: null, // 初始化为普通用户
      roomId: '',
      shareRoomUser: '',
      roomDevices: [],
      roomDeviceName: '',
      searchRoom: '',
      shareDialog: false,
      roomList: [],
      searchDevice: '',
      searchPhone: '',
      accountList: [{
        value: '',
      }],
      loginForm: {},
      userInfo: '',
      bandDialog: false,
      userId: '',
      deviceIds: [],
      deviceTimer: null,
      deviceList: [],
      bandDeviceList: [],
      versionList: [],
      fileObj: null,
      deviceObj: {},
      form: {},
      userList: [],
      warningList: [],
      dialogTitle: '',
      seen: true,
      dialogVisible: false,
      deOptions: deOptions,
      // 1 医生 2 患者
      dialogType: 1,
      loading: false,
      patientList: [],
      options: [{
        label: '男',
        value: '男'
      },
      {
        label: '女',
        value: '女'
      }]
    };
  },
  beforeDestroy() {
    if (this.deviceTimer) clearInterval(this.deviceTimer);
  },
  mounted() {
    this.userInfo = window.localStorage.getItem('userId')
    this.queryVersion()
    this.queryDevice()
    this.queryUser()
    this.getRoomList()
    this.deviceTimer = setInterval(() => {
      this.queryDevice()
    }, 1000 * 10)    // 增加5秒轮询，查询设备最新状态
    // 确保在 mounted 中调用
    this.loadUserPrivileges();
  },
  computed: {
    // 过滤后的用户列表
    filteredUserList() {
      return this.userList.filter(user => user.showable === 1);
    },
  },
  methods: {
    //删除用户
    deleteUser(phone) {
      delUser({ phone }).then((res) => {
        if (res.code == 200) {
          this.$message.success('用户删除成功');
          this.queryUser(); // 刷新用户列表;
          console.log('删除用户成功')
        } else {
          this.$message.error('删除用户失败');
          console.log('删除用户失败')
        }
      }).catch((error) => {
      console.error('删除用户失败1', error);
      });
  },
    //获取用户的权限信息
    getPrivilegeText(phone) {
      getUser({ phone })
        .then((res) => {
          const privileges = res.data[0].privileges;
          const privilegeMap = {
            0: '管理员',
            1: '普通用户',
            2: '申请中',
            3: '拒绝申请'
          };
          // 更新 userPrivileges 对象
          this.$set(this.userPrivileges, phone, privilegeMap[privileges]);
        })
        .catch((e) => {
          console.log('===err', e);
          this.$set(this.userPrivileges, phone, '尚未设置权限');
        });
    },
    loadUserPrivileges() {
      this.userList.forEach(user => {
        this.getPrivilegeText(user.phone);
      });
    },
    downloadFile(file) {
      //  let url = window.location.protocol + '//' + window.location.hostname + ':10999' + '/resource/' +file
      let url = 'http://218.94.159.100:10999/resource/' + file
      window.open(url)
    },
    handleLogin() {
      login(this.loginForm).then(res => {
        if (res.code == 200) {
          this.$message.success('登录成功')
          this.userInfo = res.data.id
          window.localStorage.setItem('userId', this.userInfo)
        } else {
          this.$message.error(res.msg)
        }
      })
        .catch(err => {
          this.$message.error(res.msg)
        })
    },
    loginOut() {
      window.localStorage.removeItem('userId')
      window.location.reload()
    },
    changFile(file, fileList) {
      this.fileObj = file.raw
    },
    queryDevice() {
      getDevice({
        device: this.searchDevice
      }).then((res) => {
        this.deviceList = res.data
        this.bandDeviceList = res.data.map(item => {
          return {
            id: item.id,
            label: item.name,
            key: item.id,
          }
        })
        res.data.forEach((item) => {
          this.deviceObj[item.id] = item.name
        })
        // this.queryUser()
      }).catch((e) => {
        console.log('===err', e);
      })
    },
    queryVersion() {
      getVersion().then((res) => {
        this.versionList = res.data
      }).catch((e) => {
        console.log('===err', e);
      })
    },
    addAccount() {
      if (this.accountList[this.accountList.length - 1].value == '') {
        this.$message.warning('请将本次填写完整再添加')
      } else {
        this.accountList.push({
          value: ''
        })
      }
    },
    delAccount(i) {
      if (this.accountList.length > 1) {
        this.accountList.splice(i, 1)
      } else {
        this.$message.warning('已经是最后一个啦')
      }
    },
    openBand(row) {
      this.bandDialog = true
      this.userId = row.id
      this.queryUserDeviceList(row.id)
      queryUserDevice({
        userId: row.id
      }).then(res => { })
    },
    bandUser() {
      bandUserDevice({
        userId: this.userId,
        deviceIds: this.deviceIds,
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.closeBand()
        }
      })
    },
    queryUserDeviceList(id) {
      queryUserBandList({
        userId: id
      }).then(res => {
        this.deviceIds = res.data.map(item => item.deviceId)
      })
    },
    closeBand() {
      this.bandDialog = false
      this.userId = ''
      this.deviceIds = []
    },
    createShareUser() {

    },
    getRoomList() {
      getRoomList({ name: this.searchRoom }).then(res => {
        if (res.code == 200) {
          this.roomList = res.data
        }
      })
    },
    openShare(roomId) {
      this.shareDialog = true
      this.roomId = roomId
      getRoomList({ id: roomId }).then(res => {
        if (res.code == 200) {
          this.roomDevices = res.data
          this.roomDeviceName = res.data.map(item => {
            return item.name
          }).join(', ')
        }
      })
    },
    shareRoom() {
      if (this.roomDevices.length > 0 && this.shareRoomUser) {
        let deviceIds = this.roomDevices.map(item => {
          return item.id
        })
        shareRoom({
          sceneId: this.roomId,
          deviceIds,
          userId: this.shareRoomUser
        }).then(res => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.closeShare()
          } else {
            this.$message.warning(res.msg)
          }
        })
      } else {
        this.$message.warning('该房间无设备或未选择要分享的用户')
      }

    },
    closeShare() {
      this.shareDialog = false
      this.roomDevices = []
      this.roomDeviceName = ''
      this.roomId = ''
      this.shareRoomUser = ''
    },
    queryUser: function () {
      getUser({ phone: this.searchPhone }).then((res) => {
        this.userList = res.data;
        // console.log(this.userList[0].showable)
        // 在 userList 更新后调用
        this.loadUserPrivileges();
      }).catch((e) => {
        console.log('===err', e);
      })
    },
    handleUser: function () {
      let accounts = []
      if (this.accountList && this.accountList.length > 0) accounts = this.accountList.map(item => item.value);
      // 判断 this.form.name 是否为空，如果为空则设置为 this.form.phone 的后四位
      if (!this.form.name && this.form.phone) {
        this.form.name = this.form.phone.slice(-4); // 获取 phone 的后四位
      }
      addUser({
        "name": this.form.name,
        "phone": this.form.phone,
        //"password": this.form.password,
        // "association_device": this.form.association_device.join(','),
        "shareAccount": accounts.join(','),
        "parentId": "",
        //在这里加一条，把用户选择的按钮的id的值作为privileges的值传给接口
        "privileges": this.privilegeId,
      }).then((res) => {
        this.queryUser()
        this.form = {}
        if (res.code == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    upUser: function () {
      let accounts = []
      if (this.accountList && this.accountList.length > 0) accounts = this.accountList.map(item => item.value);
      editUser({
        "name": this.form.name,
        "phone": this.form.phone,
        //"password": this.form.password,
        "shareAccount": accounts.join(','),
        // "association_device": this.form.association_device.join(','),
        id: this.form.id,
        "privileges": this.privilegeId,   //加了权限
      }).then((res) => {
        this.queryUser()
        this.form = {}
      })
    },
    /*
    deleteUser: function(id){
      //补充这里的代码
      delUser({
        id
      }).then((res) => {
          this.queryUser()
          this.form = {}
      })
    },
    */
    upDevice: function () {
      editDevice(this.form).then((res) => {
        this.queryDevice()
        this.form = {}
      })
    },
    deleteVersion: function (id) {
      delVersion({
        id
      }).then((res) => {
        this.queryVersion()
        this.form = {}
      })
    },
    deleteDevice: function (id) {
      delDevice({
        id
      }).then((res) => {
        this.queryDevice()
        this.form = {}
      })
    },
    modifyUser: function (type) {
      if (type == 1 && this.dialogTitle.includes('添加')) {
        this.handleDevice()
        this.dialogVisible = false;
      } else if (type == 1 && this.dialogTitle.includes('编辑')) {
        this.upDevice()
        this.dialogVisible = false;
      } else if (type == 2 && this.dialogTitle.includes('添加')) {
        this.handleUser()
        this.dialogVisible = false;
      } else if (type == 2 && this.dialogTitle.includes('编辑')) {
        this.upUser()
        this.dialogVisible = false;
      } else if (type == 3 && this.dialogTitle.includes('添加')) {
        const formData = new FormData();
        this.loading = true
        formData.append('file', this.fileObj);
        formData.append('name', this.form.name);
        formData.append('description', this.form.description);
        addVersion(formData).then((res) => {
          console.log("res", res);
          this.dialogVisible = false;
          this.$refs.upload.clearFiles();
          this.queryVersion()
          this.form = {}
          this.loading = false
        })
      }
    },
    editItem: function (item, type) {
      console.log('======item', item);
      if (type == 1) {
        this.form = item
        // this.$set(this.form, 'name', item.name)
        // this.$set(this.form, 'port', item.port)
        // this.$set(this.form, 'id', item.id)
      } else {
        this.accountList = item.shareAccount ? item.shareAccount.split(',').map((item) => {
          return {
            value: item
          }
        }) : [{
          value: ''
        }]
        this.$set(this.form, 'name', item.name)
        this.$set(this.form, 'phone', item.phone)
        //this.$set(this.form, 'password', item.password)
        // this.$set(this.form, 'association_device', item.association_device.split(',').map((item)=> Number(item)))
        this.$set(this.form, 'id', item.id)
        // 修改：将当前用户的权限值赋给 privilegeId
        this.privilegeId = item.privileges; // 确保 item.privileges 是权限值（0, 1, 2, 3）
      }
      this.dialogVisible = true;
      this.dialogType = type;
      this.dialogTitle = `编辑${type == 1 ? '设备' : '用户'}`
    },
    handleChange: function (val) {
      console.log('======val', val);
    },
    initData: function () {
      this.$refs.upload && this.$refs.upload.clearFiles();
      this.form = {}
      this.accountList = [{
        value: ''
      }]
    },
    handleClose: function (done) {
      this.initData()
      done()
    },

  },
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

.dot {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #409EFF;
  box-shadow: 0px 0px 4px 1px #63abf5;
  margin-right: 6px;
  position: relative;
  top: -1px;
}

.outline {
  background: #909399;
  box-shadow: 0px 0px 4px 1px #b2b3b6;
}

.header-wrap {
  height: 40px;
  background: linear-gradient(to right, #285dff, #5378ff);
  padding: 0 20px;
  color: #fff;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}

.fdr {
  display: flex;
  flex-direction: row;
}

.fdc {
  display: flex;
  flex-direction: column;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.doctor-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
}

.patient-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.title-wrap {
  margin-bottom: 20px;
  font-weight: bold;
}

.loginPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("./assets/login.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.loginPage-title {
  width: 100%;
  height: 60px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  font-size: 30px;
  letter-spacing: 3px;
  position: relative;
  top: -40px;
}

.loginForm {
  width: 400px;
  height: 360px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 6px 1px #07325c;
  margin: 0 auto;
}

.loginForm-title {
  width: 100%;
  height: 80px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: center;
  line-height: 100px;
  letter-spacing: 6px;
  background: #fff;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.loginForm-form {
  width: calc(100% - 60px);
  padding: 12px 30px;
  height: 160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.loginForm-input .el-input__inner {
  height: 50px !important;
  line-height: 50px !important;
  background: #f1f2f3;
  border: none;
  font-size: 16px;
}

.loginForm-button {
  width: calc(100% - 50px);
  height: 40px;
  letter-spacing: 10px;
  font-size: 16px !important;
  margin-left: 25px !important;
}
</style>
